/*
* Footer
*/

.footer {
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    color: #fff;
    background-color: #1a1919;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 820px) and (orientation: landscape) {
        padding: 4% 0;
    }

    @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
        padding: 4% 0;
    }

    .logo-ctr-1 {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;

        > img {
            width: 60%;
        }

        @media screen and (max-width: 820px) and (orientation: landscape) {
            margin-bottom: .7rem;
        }

        @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            margin-bottom: .5rem;
        }

        span {
            width: 25%;
            margin-right: 1rem;

            @media screen and (max-width: 820px) and (orientation: landscape) {
                width: 12.5%;
                margin-right: .5rem;
            }

            @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                width: 10%;
                margin-right: .4rem;
            }

            .logo-codm {
                width: 100%;
                height: 100%;
                margin-right: 1rem;
            }
        }

        a {
            width: 8%;
            margin: 0 .5rem;

            @media screen and (max-width: 820px) and (orientation: landscape) {
                width: 4%;
                margin: 0 .2rem;
            }

            @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                width: 5%;
                margin: 0 .2rem;
            }

            .social {
                width: 100%;
                cursor: pointer;
    
                &.fb:hover {
                    content: url("../../img/footer/Logo_Facebook_Hover.png");
                }
    
                &.ig:hover {
                    content: url("../../img/footer/Logo_IG_Hover.png");
                }
    
                &.yt:hover {
                    content: url("../../img/footer/Logo_YT_Hover.png");
                }
            }
        }
    }

    .logo-ctr-2 {
        margin-bottom: 1.5rem;

        @media screen and (max-width: 820px) and (orientation: landscape) {
            margin-bottom: .7rem;
        }

        @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            margin-bottom: .5rem;
        }

        > img {
            width: 15%;
            margin: 0 .5rem;

            @media screen and (max-width: 820px) and (orientation: landscape) {
                width: 12%;
                margin: 0 .2rem;
            }

            @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                width: 12%;
                margin: 0 .2rem;
            }
        }
    }

    .copyright {
        margin: 0 auto;
        width: 38%;
        font-size: .6rem;

        @media screen and (max-width: 820px) and (orientation: landscape) {
            width: 34%;
            font-size: .5rem;
        }

        @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            font-size: .5rem;
        }
    }
}