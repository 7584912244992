/*
* Form
*/

.form,
.finish {
  &.form-first {
    background: url('../../img/form/CODM-Major-Series-2-Q1-min.png') no-repeat;
    background-position: center top;
    background-size: 100% auto;
  }

  &.form-second {
    background: url('../../img/form/CODM-Major-Series-2-Q2-min.png') no-repeat;
    background-position: center top;
    background-size: 100% auto;
  }

  &.form-last {
    // background: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/regismp/clanwar_bg_desktop.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
  }

  &.bg-finish {
    &__first {
      background: url('../../img/form/CODM-Major-Series-2-Q1-min.png') no-repeat;
      background-position: center top;
      background-size: 100% auto;
    }

    &__second {
      background: url('../../img/form/CODM-Major-Series-2-Q2-min.png') no-repeat;
      background-position: center top;
      background-size: 100% auto;
    }

    &__last {
      background: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/regismp/clanwar_bg_desktop.jpg');
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 100% auto;
    }
  }

  .form-nav {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1% 2%;

    > span {
      background-color: #fefefecb;
      font-size: 1rem;
      padding: 0.15% 1%;
      margin-right: 0.5%;
      margin-top: 0.5;
    }

    .button {
      margin-left: 1%;
      width: 17vh;
      height: 6vh;
      border: none;
      display: inline-block;

      &.logout {
        background: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/minorseries/Button_Logout.png')
          center no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .content {
    padding-bottom: 2%;

    .form__container {
      margin-top: 10%;
      margin-bottom: 8%;

      .form-major-series {
        background: #f3f3f3;
        padding: 4% 3.5%;

        .content {
          .major-series {
            &__title {
              text-align: center;

              h2 {
                font-family: 'GunarBold';
                color: #000;
                font-size: 4vmin;
                margin-bottom: 0.5rem;
              }

              .line {
                width: 100%;
                height: 7px;
                display: flex;

                &-dark {
                  background: #818181;
                  flex-basis: 15%;
                }

                &-light {
                  background: #a3a3a3;
                  flex-basis: 70%;
                }
              }

              .desc-title {
                > p {
                  font-family: 'GunarBold';
                  font-size: 1.2rem;
                  margin: 4% 0;
                }
              }

              .desc-content {
                margin: 4% 0;

                > p {
                  font-family: 'GunarMedium';
                  font-size: 1.2rem;
                }
              }

              .button {
                width: 17vh;
                height: 6vh;
                border: none;
                margin-bottom: 3%;
                display: inline-block;

                &.edit {
                  background: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/minorseries/Button_Edit.png')
                    center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            &__desc {
              margin: 3% 0 4.5%;

              > p {
                font-family: 'GunarDemiBold';
                font-size: 0.85rem;
                text-align: center;
              }
            }

            &__accordion {
              > .card {
                background: #2b2622;
                border: none;
                border-radius: 0;
                margin: 1% 0;

                .card-header {
                  text-align: center;
                  padding: 0;
                  cursor: pointer;

                  > p {
                    font-family: 'GunarBold';
                    color: #a3a3a3;
                    font-size: 1.5rem;
                    margin: 0.3% 0;

                    @media screen and (max-width: 820px) and (orientation: landscape) {
                      font-size: 1rem;
                      margin: 1% 2%;
                    }

                    @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                      font-size: 1.2rem;
                    }
                  }
                }

                .card-body {
                  background: #f3f3f3;
                  font-family: 'GunarMedium';
                  font-size: 1rem;

                  .bold {
                    font-family: 'GunarBold';
                  }

                  ul {
                    text-align: center;
                  }
                }

                #collapseTwo {
                  text-align: justify;

                  ul {
                    text-align: justify;
                  }

                  ol {
                    text-align: justify;
                  }
                }

                #collapseThree {
                  text-align: justify;

                  ul {
                    text-align: justify;
                  }

                  ol {
                    text-align: justify;
                  }
                }
              }
            }

            &__step {
              display: flex;
              justify-content: space-between;
              margin-top: 8%;
              height: 3rem;

              @media screen and (max-width: 820px) and (orientation: landscape) {
                height: 2.5rem;
              }

              @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                height: 2.8rem;
              }

              .step {
                flex-basis: 48%;
                border-bottom: 0.3rem solid #2b2520;
                display: flex;
                justify-content: center;
                align-items: center;

                &.active {
                  background-color: #ffe500;
                }

                &.passed {
                  background-color: #00ff5a;

                  > p {
                    > img {
                      vertical-align: baseline;
                    }
                  }
                }

                > p {
                  color: #2b2622;
                  font-family: 'GunarBold';
                  font-size: 1.3rem;

                  @media screen and (max-width: 820px) and (orientation: landscape) {
                    font-size: 1rem;
                  }

                  @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                    font-size: 1.2rem;
                  }
                }
              }
            }

            &__form {
              margin-top: 5%;

              .form {
                > form {
                  .anggota-form {
                    padding-bottom: 1.2vw;
                    
                    .field-title {
                      background: #2b2520;
                      margin-bottom: 2%;

                      > p {
                        padding: 0.3% 0;
                        font-family: 'GunarBold';
                        font-size: 1.3rem;
                        color: #f3f3f3;
                        padding-left: 2%;
                      }
                    }

                    .field-desc {
                      font-family: 'GunarMedium';
                      color: #a3a3a3;
                      font-size: 1rem;

                      @media screen and (max-width: 820px) and (orientation: landscape) {
                        font-size: 0.8rem;
                      }

                      @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                        font-size: 0.9rem;
                      }
                    }

                    .member-openid {
                      margin-bottom: 5%;
                    }
                  }

                  .form-content {
                    margin: 4% 0 8% 0;
                    position: relative;

                    .title-form {
                      font-family: 'GunarBold';
                      font-size: 1.3rem;
                      line-height: 1;

                      @media screen and (max-width: 820px) and (orientation: landscape) {
                        font-size: 1rem;
                      }

                      @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                        font-size: 1rem;
                      }

                      > span {
                        color: #ff0109;
                        font-size: 2rem;
                        vertical-align: sub;

                        @media screen and (max-width: 820px) and (orientation: landscape) {
                          font-size: 1.6rem;
                        }

                        @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                          font-size: 1.6rem;
                        }
                      }
                    }

                    .desc-form {
                      font-family: 'GunarMedium';
                      color: #a3a3a3;
                      font-size: 1rem;
                      margin-bottom: .4vw;

                      @media screen and (max-width: 820px) and (orientation: landscape) {
                        font-size: 0.8rem;
                      }

                      @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                        font-size: 0.9rem;
                      }
                    }

                    .desc-img {
                      width: 20%;
                      margin: 1% 0% 2% 0%;
                    }

                    .input {
                      width: 95%;
                      border: none;
                      background-color: #e4e4e4;
                      height: 2.3rem;
                      font-family: 'GunarBold';
                      padding-left: 1%;
                    }

                    .error_message {
                      width: 100%;
                      height: 3.5vw;
                      color: red;
                      font-size: 2.1vmin;
                      position: absolute;
                      bottom: -4vw;
                      left: 0;
                    }
                  }

                  .openid-img {
                    width: 70%;
                    margin: 2% 0;
                  }
                }
              }

              .error {
                font-size: 1rem;
                color: #ff0109;
                padding: .5vw;
              }

              .button {
                width: 17vh;
                height: 6vh;
                border: none;

                &.next {
                  background: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/minorseries/Button_Next.png')
                    center no-repeat;
                  background-size: 100% 100%;
                }

                &.back {
                  background: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/minorseries/Button_Back.png')
                    center no-repeat;
                  background-size: 100% 100%;
                }

                &.submit {
                  background: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/minorseries/Button_Submit.png')
                    center no-repeat;
                  background-size: 100% 100%;
                }
              }

              .notes {
                font-family: 'GunarMedium';
                color: #a3a3a3;
                font-size: 1rem;
                margin: 2% 0;

                @media screen and (max-width: 820px) and (orientation: landscape) {
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
