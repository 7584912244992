/*
* Fonts
*/

@font-face {
    font-family: "GunarBlack";
    src: url("../../fonts/Gunar\ Black.ttf");
}

@font-face {
    font-family: "GunarBlackItalic";
    src: url("../../fonts/Gunar\ Black\ Italic.ttf");
}

@font-face {
    font-family: "GunarBold";
    src: url("../../fonts/Gunar\ Bold.ttf");
}

@font-face {
    font-family: "GunarBoldItalic";
    src: url("../../fonts/Gunar\ Bold\ Italic.ttf");
}

@font-face {
    font-family: "GunarDemiBold";
    src: url("../../fonts/Gunar\ DemiBold.ttf");
}

@font-face {
    font-family: "GunarDemiBoldItalic";
    src: url("../../fonts/Gunar\ DemiBold\ Italic.ttf");
}

@font-face {
    font-family: "GunarExtraBold";
    src: url("../../fonts/Gunar\ ExtraBold.ttf");
}

@font-face {
    font-family: "GunarExtraBoldItalic";
    src: url("../../fonts/Gunar\ ExtraBold\ Italic.ttf");
}

@font-face {
    font-family: "GunarHeavy";
    src: url("../../fonts/Gunar\ Heavy.ttf");
}

@font-face {
    font-family: "GunarHeavyItalic";
    src: url("../../fonts/Gunar\ Heavy\ Italic.ttf");
}

@font-face {
    font-family: "GunarItalic";
    src: url("../../fonts/Gunar\ Italic.ttf");
}

@font-face {
    font-family: "GunarLight";
    src: url("../../fonts/Gunar\ Light.ttf");
}

@font-face {
    font-family: "GunarLightItalic";
    src: url("../../fonts/Gunar\ Light\ Italic.ttf");
}

@font-face {
    font-family: "GunarMedium";
    src: url("../../fonts/Gunar\ Medium.ttf");
}

@font-face {
    font-family: "GunarMediumItalic";
    src: url("../../fonts/Gunar\ Medium\ Italic.ttf");
}

@font-face {
    font-family: "GunarThin";
    src: url("../../fonts/Gunar\ Thin.ttf");
}

@font-face {
    font-family: "GunarThinItalic";
    src: url("../../fonts/Gunar\ Thin\ Italic.ttf");
}

@font-face {
    font-family: "Gunar";
    src: url("../../fonts/Gunar.ttf");
}

@font-face {
    font-family: "RefrigeratorDeluxeBold";
    src: url("../../fonts/RefrigeratorDeluxe-Bold.ttf");
}